<template>
  <section class="p-2">
    <h2>{{ $t('service.general_fare_management.title') }}</h2>

    <validation-observer ref="fareManagementFormRef" tag="form" @submit.prevent="onSubmit">
      <b-card class="border my-2">
        <b-form-checkbox
          v-model="product.mainVariant.priceOnRegularLine"
          :disabled="$route.name === 'productShow'"
          name="check-button"
          class="mt-25 my-md-50"
          switch
          inline
          :value="false"
          :unchecked-value="true"
        >
          {{ $t('service.on_quote') }}
        </b-form-checkbox>
      </b-card>

      <b-card class="border">
        <div class="d-flex justify-content-between">
          <div>
            <b-form-checkbox v-model="product.mainVariant.priceOnRegularLine" :disabled="$route.name === 'productShow'" name="check-button" class="mt-25 my-md-50" switch inline>
              {{ $t('service.general_fare_management.regular_flight') }}
            </b-form-checkbox>
          </div>

          <app-btn-add v-if="product.mainVariant.priceOnRegularLine && $route.name !== 'productShow'" @click="regularFlightMode = 'add'">
            {{ $t(`service.general_fare_management.${$store.getters['app/mdAndUp'] ? 'add_' : ''}regular_flight`) }}
          </app-btn-add>
        </div>

        <template v-if="product.mainVariant.priceOnRegularLine">
          <app-collapse v-for="regularLine in product.mainVariant.regularLines" :key="regularLine.id" class="mt-3" type="border" accordion>
            <app-collapse-item
              @visible="
                () => {
                  if (!regularLine.destinations) product.fetchRegularLines(regularLine.id)
                }
              "
            >
              <template #header>
                <div class="w-100 d-flex justify-content-between">
                  <div class="lead collapse-title">
                    <div class="d-block d-lg-inline mr-3">{{ $t('common.start_at') }} : {{ $moment(regularLine.visibilityStartAt).format('YYYY/MM/DD') }}</div>
                    <div class="d-block d-lg-inline">{{ $t('common.end_at') }} : {{ $moment(regularLine.visibilityEndAt).format('YYYY/MM/DD') }}</div>

                    <div class="mt-2">{{ $t('service.general_fare_management.from_to_base') }}</div>
                    <div class="d-inline">{{ regularLine.base.cityBase.city }}</div>
                    <feather-icon
                      v-if="$route.name !== 'productShow'"
                      class="ml-1 ml-md-2 mr-2"
                      style="cursor: pointer"
                      size="18"
                      icon="Edit2Icon"
                      color="#e1ca93"
                      @click.stop="
                        () => {
                          regularFlightMode = 'edit'
                          selectedRegularLine = _cloneDeep(regularLine)
                        }
                      "
                    />
                  </div>
                  <div>
                    <b-form-checkbox v-model="regularLine.active" class="pt-25 mr-25 mr-md-1" name="check-button" switch inline disabled />
                    <feather-icon v-if="$route.name !== 'productShow'" style="cursor: pointer" size="18" icon="TrashIcon" color="#ea5455" @click.stop="modalDeleteItem(regularLine.id)" />
                    <app-btn-add
                      v-if="$route.name !== 'productShow'"
                      class="ml-1 ml-md-2 mr-25 mr-md-0"
                      @click.stop="
                        () => {
                          destinationMode = 'add'
                          selectedDestination.rlId = regularLine.id
                          selectedDestination.base = _cloneDeep(regularLine.base)
                        }
                      "
                    >
                      {{ $t(`service.general_fare_management.${$store.getters['app/mdAndUp'] ? 'add_a_' : ''}destination`) }}
                    </app-btn-add>
                  </div>
                </div>
              </template>

              <div v-if="regularLine.destinations && regularLine.destinations.length">{{ $t('service.general_fare_management.destinations') }} :</div>

              <b-card v-for="destination in regularLine.destinations" :key="destination.id" class="border mb-2">
                <div class="w-100 d-flex justify-content-between mt-1">
                  <div>
                    <div>{{ $t('address.city') }}</div>
                    <h4 class="d-inline">{{ destination.address.city }}</h4>
                    <feather-icon
                      v-if="$route.name !== 'productShow'"
                      class="ml-1 mr-2"
                      style="cursor: pointer"
                      size="18"
                      icon="Edit2Icon"
                      color="#e1ca93"
                      @click="
                        () => {
                          destinationMode = 'edit'
                          selectedDestination = {
                            ..._cloneDeep(destination),
                            rlId: regularLine.id,
                            base: _cloneDeep(regularLine.base),
                          }
                        }
                      "
                    />
                    <app-link-gmap-address class="mr-md-5" :query="[destination.address.address, destination.address.city, destination.address.countryCode]" />
                  </div>
                  <div class="mr-md-2">
                    <b-form-checkbox v-model="regularLine.active" :disabled="$route.name === 'productShow'" class="pt-25" name="check-button" switch inline disabled />
                    <feather-icon
                      v-if="$route.name !== 'productShow'"
                      style="cursor: pointer"
                      size="18"
                      icon="TrashIcon"
                      color="#ea5455"
                      @click="modalDeleteDestination(regularLine.id, destination.id)"
                    />
                    <app-btn-add
                      v-if="$route.name !== 'productShow'"
                      class="ml-1 ml-md-2"
                      @click.stop="
                        () => {
                          depositZoneMode = 'add'
                          selectedDZ.rlId = regularLine.id
                          selectedDZ.destinationId = destination.id
                        }
                      "
                    >
                      {{ $t(`service.helicopter.bases.${$store.getters['app/mdAndUp'] ? 'add_a_' : ''}deposit_zone`) }}
                    </app-btn-add>
                  </div>
                </div>

                <b-row class="mt-2">
                  <b-col cols="6" lg="3" class="mb-2">
                    <div>{{ $t('service.general_fare_management.flying_time') }} :</div>
                    <h4 class="d-inline mr-2">{{ destination.flyingTime }}{{ $t('common.min') }}</h4>
                  </b-col>

                  <b-col cols="6" lg="3" class="mb-2">
                    <div>{{ $t('service.general_fare_management.price_vat_free') }} :</div>
                    <h4 class="d-inline mr-2">{{ destination.priceExcludingTaxes | priceFormat }}</h4>
                  </b-col>

                  <b-col cols="6" lg="3" class="mb-2">
                    <div>{{ $t('common.vat') }} :</div>
                    <h4 class="d-inline mr-2">{{ destination.vatRate }} %</h4>
                  </b-col>

                  <b-col cols="6" lg="3" class="mb-2">
                    <div>{{ $t('service.general_fare_management.price_vat_included') }} :</div>
                    <h4 class="d-inline mr-2">{{ destination.priceIncludingTaxes | priceFormat }}</h4>
                  </b-col>
                </b-row>

                <template v-if="destination.depositZones.length">
                  <div class="mt-2">{{ $t('service.helicopter.bases.deposits_zones') }}</div>

                  <b-table
                    class="position-relative"
                    thead-class="d-none"
                    striped
                    :items="destination.depositZones"
                    responsive
                    :fields="tableColumns"
                    primary-key="id"
                    show-empty
                    :empty-text="$t('table.no_record_found')"
                  >
                    <template #cell(dzAddress)="{ item }">
                      <span>{{ `${item.address} ${item.postalCode} ${item.city}` }}</span>
                    </template>

                    <template #cell(actions)="{ item }">
                      <div align="right">
                        <app-data-table-actions
                          table-name="deposit-zone"
                          display-mode="inline-icon"
                          :show-add="false"
                          :show-duplicate="false"
                          :show-show="false"
                          :show-delete="$route.name !== 'productShow'"
                          :show-edit="$route.name !== 'productShow'"
                          @delete="modalDeleteDZ(regularLine.id, destination.id, item.id)"
                          @edit="
                            () => {
                              depositZoneMode = 'edit'
                              selectedDZ = {
                                ..._cloneDeep(item),
                                rlId: regularLine.id,
                                destinationId: destination.id,
                                destinationCity: destination.address.city,
                              }
                            }
                          "
                        />
                      </div>
                    </template>
                  </b-table>
                </template>
              </b-card>
            </app-collapse-item>
          </app-collapse>
        </template>
      </b-card>
      <footer-form-service v-if="$route.name === 'productShow'" class="mt-4" previous next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />
      <footer-form-service v-else class="mt-4" previous save save-next @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />
    </validation-observer>

    <!-- REGULAR LINE SIDEBAR -->
    <app-sidebar-form
      form-name="regular-line"
      :edition-mode-title="regularFlightMode"
      :edition="!!regularFlightMode"
      :disabled="regularLineLoader"
      :title="$t('service.general_fare_management.regular_flight')"
      :entity="selectedRegularLine"
      @update:edition="
        editionMode => {
          if (!editionMode) {
            regularFlightMode = null
            selectedRegularLine = _cloneDeep(initRegularLine)
          }
        }
      "
      @update:entity="onSubmitRegularLine()"
      @reset="selectedRegularLine = _cloneDeep(initRegularLine)"
    >
      <template #fields>
        <b-row class="mb-1">
          <b-col md="6">
            <app-datepicker
              id="startAt"
              v-model="selectedRegularLine.visibilityStartAt"
              rules="required"
              :max="selectedRegularLine.visibilityEndAt"
              :label="$t('common.start_at')"
            />
          </b-col>
          <!--DATE END-->
          <b-col md="6">
            <app-datepicker
              id="endAt"
              v-model="selectedRegularLine.visibilityEndAt"
              rules="required"
              :min="selectedRegularLine.visibilityStartAt"
              :label="$t('common.end_at')"
            />
          </b-col>
        </b-row>
        <validation-provider #default="{ errors }" :name="$t('service.general_fare_management.from_to_base')" rules="required" class="validation-required">
          <b-form-group
            :label="$t('service.general_fare_management.from_to_base')"
            label-for="base"
            class="validation-required"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              v-model="selectedRegularLine.base"
              input-id="base"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('service.general_fare_management.from_to_base')"
              :options="product.bases"
              :clearable="false"
              @open.once="product.fetchBases()"
            >
              <template #selected-option="{ cityBase }">{{ cityBase.city }}</template>
              <template #option="{ cityBase }">{{ cityBase.city }}</template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider>
          <b-form-checkbox v-model="selectedRegularLine.active" name="regular-line-active" switch inline>
            {{ $t('common.active') }}
          </b-form-checkbox>
        </validation-provider>
      </template>
    </app-sidebar-form>

    <!-- DESTINATION SIDEBAR -->
    <app-sidebar-form
      form-name="destination"
      :edition-mode-title="destinationMode"
      :edition="!!destinationMode"
      :disabled="destinationLoader"
      :title="$t('service.general_fare_management.destination')"
      :entity="selectedRegularLine"
      @update:edition="
        editionMode => {
          if (!editionMode) {
            destinationMode = null
            selectedDestination = _cloneDeep(initDestination)
          }
        }
      "
      @update:entity="onSubmitDestination()"
      @reset="selectedDestination = _cloneDeep(initDestination)"
    >
      <template #fields>
        <div style="font-size: 13px">{{ $t('service.general_fare_management.destination') }} :</div>
        <div v-if="selectedDestination.base" class="ml-50 mb-2">
          {{ selectedDestination.base.cityBase.city }}
        </div>

        <div style="font-size: 13px">{{ $t('service.general_fare_management.from_to_destination') }} :</div>

        <app-input id="address" v-model="selectedDestination.address.address" class-group="mb-md-2" :label="$t('common.address')" required />

        <app-input id="postalCode" v-model="selectedDestination.address.postalCode" class-group="mb-md-2" :label="$t('address.postal_code')" required />

        <app-input id="fromToDestination" v-model="selectedDestination.address.city" class-group="mb-md-2" :label="$t('address.city')" required />

        <!-- Country -->
        <enum-select
          v-model="selectedDestination.address.countryCode"
          :label="$t('common.country')"
          text="text"
          class-group="mb-md-2"
          enum-class="CountryCode"
          :clearable="false"
          required
        />

        <app-input
          id="flyingTime"
          v-model.number="selectedDestination.flyingTime"
          class-group="mb-md-2"
          type="number"
          rules="required|positive|integer"
          :label="$t('service.general_fare_management.flying_time')"
          :append="$t('common.min')"
        />

        <app-input
          id="priceExcludingTaxes"
          v-model.number="selectedDestination.priceExcludingTaxes"
          class-group="mb-md-2"
          :append="getCurrency()"
          type="number"
          step="0.01"
          rules="required|positive"
          :label="$t('service.general_fare_management.price_vat_free')"
          @input="setTTC()"
        />

        <app-input
          id="vatRate"
          v-model.number="selectedDestination.vatRate"
          class-group="mb-md-2"
          type="number"
          step="0.01"
          rules="required|positive"
          :label="$t('common.vat')"
          append="%"
          @input="setTTC()"
        />

        <app-input
          id="priceIncludingTaxes"
          v-model.number="selectedDestination.priceIncludingTaxes"
          :label="$t('service.general_fare_management.price_vat_included')"
          class-group="mb-md-5"
          :append="getCurrency()"
          type="number"
          step="0.01"
          disabled
        />
      </template>
    </app-sidebar-form>

    <!-- DEPOSIT ZONE SIDEBAR -->
    <app-sidebar-form
      form-name="deposit-zone"
      :edition-mode-title="depositZoneMode"
      :edition="!!depositZoneMode"
      :disabled="depositZoneLoader"
      :title="$t('service.helicopter.bases.deposit_zone')"
      :entity="selectedRegularLine"
      @update:edition="
        editionMode => {
          if (!editionMode) {
            depositZoneMode = null
            selectedDZ = _cloneDeep(initDZ)
          }
        }
      "
      @update:entity="onSubmitDepositZone()"
      @reset="selectedDZ = _cloneDeep(initDZ)"
    >
      <template #fields>
        <div style="font-size: 13px">{{ $t('service.general_fare_management.destination') }} :</div>
        <div class="ml-50 mb-2">{{ selectedDZ.destinationCity }}</div>

        <!-- ADDRESS -->
        <validation-provider #default="{ errors }" :name="$t('common.address')" rules="required" class="validation-required">
          <b-form-group label-for="address" :label="$t('common.address')">
            <b-form-input id="address" v-model="selectedDZ.address" :state="errors[0] ? false : null" :placeholder="$t('common.address')" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Postalcode -->
        <validation-provider #default="{ errors }" :name="$t('address.postal_code')" rules="required" class="validation-required">
          <b-form-group label-for="postalCode" :label="$t('address.postal_code')">
            <b-form-input id="postalCode" v-model="selectedDZ.postalCode" :state="errors[0] ? false : null" :placeholder="$t('address.postal_code')" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- City -->
        <validation-provider #default="{ errors }" :name="$t('address.city')" rules="required" class="validation-required">
          <b-form-group label-for="city" :label="$t('address.city')">
            <b-form-input id="city" v-model="selectedDZ.city" :placeholder="$t('address.city')" :state="errors[0] ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Country -->
        <enum-select v-model="selectedDZ.countryCode" :label="$t('common.country')" text="text" enum-class="CountryCode" required />
      </template>
    </app-sidebar-form>
  </section>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'
import { omit } from 'lodash'

import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import getCurrency from '@/helpers/getCurrency'
import AppLinkGmapAddress from '@/components/AppLinkGmapAddress.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppBtnAdd from '@/components/AppBtnAdd.vue'
import AppDatepicker from '@/components/AppDatepicker.vue'
import RegularLines from '../component/RegularLines.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'GeneralFareManagement',

  components: {
    RegularLines,
    AppCollapse,
    AppCollapseItem,
    AppLinkGmapAddress,
    AppDataTableActions,
    AppSidebarForm,
    AppBtnAdd,
    AppDatepicker,
    FooterFormService,
  },

  setup(_props, ctx) {
    const { $i18n, toaster, _cloneDeep, alert, $bvModal } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')
    const isRegularFlightModeOnDB = ref(false)
    product.value.fetchFareManagement(ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(() => {
      isRegularFlightModeOnDB.value = _cloneDeep(product.value.mainVariant.priceOnRegularLine)
    })

    const nextRedirection = ref(false)
    const fareManagementFormRef = ref(null)
    const saveLoader = ref(false)
    const regularLineLoader = ref(false)
    const destinationLoader = ref(false)
    const depositZoneLoader = ref(false)
    const regularFlightMode = ref(null)
    const destinationMode = ref(null)
    const depositZoneMode = ref(null)
    const selectedRegularLine = ref({
      id: null,
      visibilityStartAt: null,
      visibilityEndAt: null,
      active: true,
      base: {
        id: null,
        active: true,
        cityBase: {
          id: null,
          addressName: null,
          address: null,
          city: null,
          countryCode: null,
          countryName: null,
          postalCode: null,
        },
      },
    })
    const initRegularLine = _cloneDeep(selectedRegularLine.value)

    const selectedDestination = ref({
      id: null,
      rlId: null,
      base: null,
      address: {
        id: null,
        addressName: null,
        address: null,
        postalCode: null,
        city: null,
        countryCode: null,
      },
      flyingTime: null,
      priceExcludingTaxes: 0,
      vatRate: 0,
      priceIncludingTaxes: 0,
      active: true,
    })
    const initDestination = _cloneDeep(selectedDestination.value)

    const selectedDZ = ref({
      id: null,
      rlId: null,
      destinationId: null,
      destinationCity: null,
      address: '',
      postalCode: '',
      city: '',
      countryCode: '',
    })
    const initDZ = _cloneDeep(selectedDZ.value)

    const modalDelete = (setting = {}) => $bvModal.msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
      title: $i18n.t('alert.delete_confirmation.title'),
      size: 'sm',
      okVariant: 'primary',
      okTitle: $i18n.t('common.yes'),
      cancelTitle: $i18n.t('common.no'),
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      centered: true,
      ...setting,
    })

    const modalDeleteItem = regularLineId => {
      modalDelete().then(value => {
        if (value) {
          alert($i18n.t('alert.delete.success'))
          product.value.deleteRegularLine(regularLineId)
        }
      })
    }

    const modalDeleteDestination = (rlId, destinationId) => {
      modalDelete().then(value => {
        if (value) {
          alert($i18n.t('alert.delete.success'))
          product.value.deleteDestination(rlId, destinationId)
        }
      })
    }

    const modalDeleteDZ = (rlId, destinationId, dzId) => {
      modalDelete().then(value => {
        if (value) {
          alert($i18n.t('alert.delete.success'))
          product.value.deleteDestinationDZ(rlId, destinationId, dzId)
        }
      })
    }

    const tableColumns = [{ key: 'dzAddress', tdClass: 'w-100' }, { key: 'actions' }]

    const setTTC = () => {
      selectedDestination.value.priceIncludingTaxes = Math.round(
        (selectedDestination.value.priceExcludingTaxes + (selectedDestination.value.priceExcludingTaxes * selectedDestination.value.vatRate) / 100) * 100,
      ) / 100
    }

    const onSubmit = () => fareManagementFormRef.value.validate().then(validForm => {
      if (validForm) {
        saveLoader.value = true
        product.value
          .patchFareManagement()
          .then(() => {
            if (nextRedirection.value) {
              $emit('next-tab')
              nextRedirection.value = false
            }
          })
          .finally(() => {
            saveLoader.value = false
          })
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    })

    // PATCH /fare-management if add/edit any entities of regularLines
    const patchFareManagementIfNeeded = () => {
      if (!isRegularFlightModeOnDB.value) {
        onSubmit()
        isRegularFlightModeOnDB.value = true
      }
    }

    // SUBMIT REGULARLINE
    const onSubmitRegularLine = () => {
      patchFareManagementIfNeeded()

      regularLineLoader.value = true
      const copyRL = _cloneDeep(selectedRegularLine.value)
      copyRL.destinations = copyRL.destinations || []
      const action = regularFlightMode.value === 'add' ? 'postRegularLine' : 'patchRegularLine'

      const payload = {
        id: selectedRegularLine.value.id,
        visibilityStartAt: selectedRegularLine.value.visibilityStartAt,
        visibilityEndAt: selectedRegularLine.value.visibilityEndAt,
        baseId: selectedRegularLine.value.base.id,
        active: selectedRegularLine.value.active,
      }
      product.value[action](payload)
        .then(res => {
          if (!copyRL.id) {
            // ADD
            product.value.mainVariant.regularLines.push({ ...copyRL, id: res.id })
          } else {
            // EDIT
            const rlIndex = product.value.mainVariant.regularLines.findIndex(rl => rl.id === copyRL.id)
            product.value.mainVariant.regularLines[rlIndex] = copyRL
          }
        })
        .finally(() => {
          regularLineLoader.value = false
        })
    }

    // SUBMIT DESTINTATION
    const onSubmitDestination = () => {
      patchFareManagementIfNeeded()

      destinationLoader.value = true
      const copyDestination = _cloneDeep(selectedDestination.value)
      copyDestination.depositZones = copyDestination.depositZones || []
      const action = destinationMode.value === 'add' ? 'postDestination' : 'patchDestination'

      product.value[action]({
        ...selectedDestination.value,
        ...omit(selectedDestination.value.address, 'id', 'addressName'),
      })
        .then(res => {
          const rlIndex = product.value.mainVariant.regularLines.findIndex(rl => rl.id === copyDestination.rlId)

          if (!product.value.mainVariant.regularLines[rlIndex].destinations) {
            product.value.fetchRegularLines(copyDestination.rlId)
            return
          }

          if (!copyDestination.id) {
            // ADD
            copyDestination.id = res.id
            product.value.mainVariant.regularLines[rlIndex].destinations.push(copyDestination)
          } else {
            // EDIT
            const destinationIndex = product.value.mainVariant.regularLines[rlIndex].destinations.findIndex(
              destination => destination.id === copyDestination.id,
            )
            product.value.mainVariant.regularLines[rlIndex].destinations[destinationIndex] = copyDestination
          }
        })
        .finally(() => {
          destinationLoader.value = false
        })
    }

    // SUBMIT DEPOSIT ZONE
    const onSubmitDepositZone = () => {
      patchFareManagementIfNeeded()

      depositZoneLoader.value = true
      const copyDepositZone = _cloneDeep(selectedDZ.value)
      const action = depositZoneMode.value === 'add' ? 'postDestinationDZ' : 'patchDestinationDZ'

      product.value[action](selectedDZ.value)
        .then(res => {
          const rlIndex = product.value.mainVariant.regularLines.findIndex(rl => rl.id === copyDepositZone.rlId)
          const destinationIndex = product.value.mainVariant.regularLines[rlIndex].destinations.findIndex(
            destination => destination.id === copyDepositZone.destinationId,
          )

          if (!copyDepositZone.id) {
            // ADD
            copyDepositZone.id = res.id
            product.value.mainVariant.regularLines[rlIndex].destinations[destinationIndex].depositZones.push(copyDepositZone)
          } else {
            // EDIT
            const depositZoneIndex = product.value.mainVariant.regularLines[rlIndex].destinations[destinationIndex].depositZones.findIndex(
              dz => dz.id === copyDepositZone.id,
            )
            product.value.mainVariant.regularLines[rlIndex].destinations[destinationIndex].depositZones[depositZoneIndex] = copyDepositZone
          }
        })
        .finally(() => {
          depositZoneLoader.value = false
        })
    }

    return {
      product,
      regularFlightMode,
      destinationMode,
      depositZoneMode,
      selectedRegularLine,
      initRegularLine,
      selectedDestination,
      initDestination,
      selectedDZ,
      initDZ,
      nextRedirection,
      fareManagementFormRef,
      saveLoader,
      regularLineLoader,
      destinationLoader,
      depositZoneLoader,
      modalDeleteItem,
      modalDeleteDestination,
      tableColumns,
      modalDeleteDZ,
      setTTC,
      onSubmit,
      onSubmitRegularLine,
      onSubmitDestination,
      onSubmitDepositZone,
      getCurrency,
    }
  },
})
</script>
