<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="vvName || name"
    :class="{ 'validation-required': isRequired }"
  >
    <component :is="label ? 'b-form-group' : 'div'" :label="label" :label-for="id">
      <b-form-datepicker
        :id="id"
        :name="name"
        :value="value"
        :state="errors[0] ? false : state"
        :placeholder="placeholder || label"
        :required="isRequired"
        :date-format-options="dateFormatOptions"
        :locale="$i18n.locale"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <span v-if="!hideErrors && errors[0]" class="text-danger">{{ deleteFieldText(errors[0]) }}</span>
    </component>
  </validation-provider>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

/**
 * @link: https://vee-validate.logaretm.com/v3/api/validation-provider.html#scoped-slot-props
 */

// TODO: Mixin for field form components
// TODO: Rules with validationn-provider

export default defineComponent({
  name: 'AppDatepicker',

  props: {
    value: [Number, String, Date],
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    vvName: { type: String, default: null },
    label: { type: String, default: '' },
    placeholder: { type: String, default: null },
    locale: { type: String, default: '' },
    rules: { type: String, default: '' },
    rulesName: { type: String, default: '' },
    required: { type: Boolean, default: false },
    rumaxlesName: { type: String, default: '' },
    hideErrors: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
    dateFormatOptions: {
      type: Object,
      default: () => ({
        year: 'numeric', month: 'short', day: '2-digit', weekday: 'short',
      }),
    },
  },

  setup(props, ctx) {
    const isRequired = computed(() => props.rules.includes('required') || props.required)
    const computedRules = computed(() => {
      let normalizeRules = props.rules
      if (isRequired && !normalizeRules.includes('required')) {
        if (!normalizeRules) normalizeRules = 'required'
        else normalizeRules += '|required'
      }
      return normalizeRules
    })

    const localeLang = props.locale || ctx.root.$i18n.locale

    // Trick to prevent 'The {field} field must be ...'
    // But bad solution about langs, I suppose...
    // vvName with default to ' ' seems work, but break the validator
    const deleteFieldText = msg => (!props.vvName && !props.name
      ? msg.replace(/( {field})/, '')
      : msg)

    return {
      isRequired,
      computedRules,
      localeLang,
      deleteFieldText,
    }
  },
})
</script>
