<template>
  <section style="z-index: 9999; overflow: auto">
    <b-form ref="formLines" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="addDestinationCity">
      <b-card v-for="(line, index) in lines" :key="index" ref="rowLines" class="border">
        <!--DATE START-->
        <b-row>
          <b-col cols="6">
            <b-form-datepicker v-model="line.dateStart" dropdown class="mb-1" :placeholder="$t('common.start_at')" :locale="$i18n.locale" :max="line.dateEnd" />
          </b-col>

          <!--DATE END-->
          <b-col cols="6">
            <b-form-datepicker v-model="line.dateEnd" :min="line.dateStart" class="mb-1" dropdown :placeholder="$t('common.end_at')" :locale="$i18n.locale" />
          </b-col>
        </b-row>
        <h4>{{ $t('common.from') }} / {{ $t('common.to') }}</h4>
        <b-row>
          <b-col cols="10">
            <b-form-select v-model="line.from" :options="lineFromOptions" />
          </b-col>
          <b-col cols="2">
            <b-button v-if="!line.validate" v-ripple.400 size="md" class="ml-1" variant="outline-success" @click="validateLine(index)">
              {{ $t('action.validate') }}
            </b-button>
          </b-col>
        </b-row>
        <!--TO-->
        <section v-if="line.validate">
          <h4 class="mt-1">{{ $t('common.from') }} / {{ $t('common.to') }} {{ $t('service.helicopter.regular_line.bis') }}</h4>
          <b-row v-for="(toDestination, j) in line.to" :key="toDestination.destination" ref="rowDestination" class="d-flex align-items-center pb-1">
            <b-col cols="2">
              <b-form-select v-model="toDestination.destination" :options="lineFromOptions" />
            </b-col>

            <!--REQUIRED SWITCH-->
            <b-col cols="2">
              <div class="form-label-group">
                <b-form-input id="flyingTime" v-model="toDestination.flyingTime" type="text" :placeholder="$t('service.helicopter.regular_line.flying_time')" />
                <label for="flyingTime">{{ $t('service.helicopter.regular_line.flying_time') }}r</label>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-label-group">
                <b-form-input id="price" v-model="toDestination.price" type="number" :placeholder="$t('service.helicopter.regular_line.price')" />
                <label for="price">{{ $t('service.helicopter.regular_line.price') }}r</label>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-label-group">
                <b-form-input id="vat" v-model="toDestination.vat" type="number" :placeholder="$t('common.vat')" />
                <label for="vat">{{ $t('common.vat') }}r</label>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-label-group">
                <b-form-input id="discount" v-model="toDestination.discount" type="number" :placeholder="$t('service.helicopter.regular_line.discount')" />
                <label for="discount">{{ $t('service.helicopter.regular_line.discount') }}r</label>
              </div>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center justify-content-center">
              <!--delete/add answer-->
              <b-button-group class="ml-2">
                <b-button v-if="line.to.length > 1" v-ripple.400 size="md" variant="outline-primary" @click="modalDeleteItem(line.to, j)">
                  <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="minus" class="text-danger" :title="$t('action.delete')" />
                </b-button>
                <b-button v-if="j === line.to.length - 1" v-ripple.400 size="md" variant="outline-primary" @click="addLineDestination(line, j)">
                  <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="plus" class="text-success" :title="$t('action.add')" />
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </section>

        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button v-ripple.400 variant="flat-danger" class="btn-icon" @click="modalDeleteItem(lines, index)">
              <feather-icon v-b-tooltip.hover.bottom.v-danger size="21" icon="Trash2Icon" class="text-danger" :title="$t('action.delete')" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
    <div class="d-flex justify-content-end my-2">
      <b-button v-ripple.400 @click="addDestinationCity">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>{{ $t('action.add') }}</span>
      </b-button>
    </div>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  name: 'RegularLines',

  mixins: [heightTransition],
  data() {
    return {
      lines: [
        {
          id: 1,
          active: true,
          from: '',
          dateStart: '',
          dateEnd: '',
          validate: false,
          to: [],
        },
      ],
      definedLocation: ['Paris', 'Bordeaux'],
      lineFromOptions: [
        { value: 'paris', text: 'Paris' },
        { value: 'bordeaux', text: 'Bordeaux' },
        { value: 'chambery', text: 'Chambery' },
      ],
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    validateLine(index) {
      this.lines[index].validate = true
      this.definedLocation.forEach(location => {
        this.lines[index].to.push({
          destination: location.toLowerCase(),
          flyingTime: '',
          price: '',
          vat: '',
          discount: '',
        })
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowDestination[0].offsetHeight + 130)
      })
    },
    modalDeleteItem(item, index) {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.delete_confirmation.message'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.alert(this.$t('alert.delete.success'))
            item.splice(index, 1)
            this.initTrHeight()
          }
        })
    },
    addLineDestination(line) {
      line.to.push({
        destination: null,
        flyingTime: null,
        price: '',
        vat: '',
        discount: '',
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowDestination[0].offsetHeight)
      })
    },
    addDestinationCity() {
      const lineSkeleton = {
        id: 1,
        active: true,
        from: '',
        dateStart: '',
        dateEnd: '',
        to: [],
      }
      lineSkeleton.id = this.lines[this.lines.length - 1].id + 1
      this.lines.push(lineSkeleton)
      this.trAddHeight(this.$refs.rowLines[0].offsetHeight + 30)
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.rowLines[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formLines.scrollHeight)
      })
    },
  },
}
</script>
<style>
[dir] .form-label-group {
  margin-bottom: 0;
}
</style>
