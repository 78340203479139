<template>
  <b-button v-ripple.400 :size="size || (mdAndDown ? 'sm' : 'md')" :block="block" v-bind="$attrs" v-on="$listeners">
    <span class="text-nowrap">
      <feather-icon v-if="!hideIcon" :icon="icon" class="mr-25" />
      <slot v-if="!hideText || $store.getters[`app/${hideText}AndUp`]">
        {{ $t('action.add') }}
      </slot>
    </span>
  </b-button>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'AppBtnAdd',

  props: {
    block: {
      type: Boolean,
      default: false,
    },
    hideText: {
      type: [Boolean, String],
      default: false,
      validator: val => [true, false, 'xs', 'sm', 'md', 'lg', 'xl'].includes(val),
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'PlusIcon',
    },
    size: {
      type: String,
      default: null,
    },
  },

  setup(_props, ctx) {
    const { $store } = ctx.root
    const mdAndDown = computed(() => $store.getters['app/mdAndDown'])

    return { mdAndDown }
  },
})
</script>
